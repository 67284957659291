<ls-questionnaire-toggle #questionnaireForm *ngIf="!isSunfyre_ARFPricingGauge_2024718Enabled; else instApprovalV2"
	toggleLabel="Confirm Invoice Approvals" [(enabled)]=isInstrumentApprovalConfirmed [toggledState]="toggledState"
	[displayConfig]="config(SUNFYRE,'q1')" [breadcrumbs]="breadcrumbs" (buttonClicked)="continue()"
	id="questionnaireFormToggle">
	<div>
		<ls-text extraClasses="ls-mb-0" [displayConfig]="config(SUNFYRE,'q1-p1')"></ls-text>
		<ul class="ls-mb-0">
			<li><ls-text [displayConfig]="config(SUNFYRE,'q1-b1')"></ls-text></li>
			<li><ls-text [displayConfig]="config(SUNFYRE,'q1-b2')"></ls-text></li>
			<li><ls-text [displayConfig]="config(SUNFYRE,'q1-b3')"></ls-text></li>
		</ul>
	</div>
</ls-questionnaire-toggle>

<ng-template #instApprovalV2>
	<ls-questionnaire #questionnaireForm [breadcrumbs]="breadcrumbs" (nextButtonClicked)="continue()"
		[isNextButtonDisabled]="invApprovalForm.invalid" id="questionnaireForm">
		<form class="d-flex flex-column ls-mt-2" *ngIf="display(INSTANT)" [formGroup]="invApprovalForm">
			<ls-text id="header" [displayConfig]="config(INSTANT,'header')" extraClasses="ls-mb-6"></ls-text>
			<div class="ls-mb-6">
				<ls-text id="listHeader" [displayConfig]="config(INSTANT,'listHeader')"
					extraClasses="ls-mb-0"></ls-text>
				<ul class="ls-mb-0">
					<li><ls-text id="bullet1" [displayConfig]="config(INSTANT,'bullet1')"
							extraClasses="ls-mb-0"></ls-text></li>
					<li><ls-text id="bullet2" [displayConfig]="config(INSTANT,'bullet2')"
							extraClasses="ls-mb-0"></ls-text></li>
					<li><ls-text id="bullet3" [displayConfig]="config(INSTANT,'bullet3')"
							extraClasses="ls-mb-0"></ls-text></li>
				</ul>
			</div>
			<div *ngFor="let fd of yn" class="ls-pb-3 d-flex align-items-center">
				<input mdbRadio [formControlName]="INV_APPROVAL" class="radio" type="radio" [id]="'fd'+fd.id"
					[value]="fd.id" />
				<label class="xl" [for]="'fd'+fd.id">
					{{ fd.name }}
				</label>
			</div>
		</form>
	</ls-questionnaire>
</ng-template>