import { Component } from "@angular/core";
import { IRouteStepData } from "../Models/Interfaces";
import { Subject } from "rxjs";
import { Params } from "@angular/router";
import { RouteStepDataService } from "../Modules/COT-Module/Services";
import { DisplayConfig, HtmlContent, Layout } from "../Models";
import { FormControl, FormGroup, ValidatorFn } from "@angular/forms";

@Component({
	selector: "ls-route-step-data-base",
	template: ""
})
/**
 * @class RouteStepDataBaseComponent should be used as the base for all onboarding screens.
 */
export abstract class RouteStepDataBaseComponent {
	public readonly SUPPORT_EMAIL_TOKEN = "!SUPPORT_EMAIL!";
	public readonly COMPANY_NAME_TOKEN = "!COMPANY_NAME!";
	public DefaultLayout?: Map<string, Layout>;
	public activeRouteData?: IRouteStepData;
	public content?: HtmlContent;
	public overriddenContent?: HtmlContent;
	protected componentTeardown$ = new Subject();
	public HEADER = "header";
	public BODY = "body";
	public BUTTONS = "buttons";
	public FOOTER = "footer";
	public replacementTokens = new Map<string, string>();
	constructor(public routeStepDataService: RouteStepDataService) {
		this.activeRouteData = this.routeStepDataService.getActiveRouteData();
		this.overriddenContent = this.activeRouteData?.htmlContent;
	}

	buttonText(blockKey: string, contentKey = this.BUTTONS) {
		return this.config(blockKey, contentKey)?.text ?? "Next";
	}
	setDefaultLayout(DefaultLayout?: Map<string, Layout>) {
		this.content = new HtmlContent({ layout: DefaultLayout });
	}
	nav(index = 0, paramMap?: Map<string, string | number>, queryParams?: Params, state?: any) {
		this.routeStepDataService.navByConfig(index, paramMap, queryParams, state);
	}
	config(contentKey: string, blockKey: string): DisplayConfig | undefined {
		return (
			this.configFrom(this.overriddenContent?.layout, contentKey, blockKey) ??
			this.configFrom(this.content?.layout, contentKey, blockKey)
		);
	}
	private configFrom(
		layout: Map<string, Layout> | undefined,
		contentKey: string,
		blockKey: string
	): DisplayConfig | undefined {
		if (!layout) return undefined;
		const blocks = layout?.get(contentKey)?.blocks;
		const display = blocks?.get(blockKey);
		if (display?.text) {
			for (const [key, value] of this.replacementTokens) {
				const re = new RegExp(key, "g");
				display.text = display.text?.replace(re, value);
			}
		}
		return display;
	}
	toolTips(contentKey: string): Map<string | number, string> | undefined {
		const overriddenTooltips = this.toolTipsFrom(this.overriddenContent?.layout, contentKey);
		const defaultToolTips = this.toolTipsFrom(this.content?.layout, contentKey);
		if (defaultToolTips === undefined) {
			return undefined;
		}
		const result = new Map<string | number, string>();
		if (defaultToolTips) {
			const keys = [...defaultToolTips.keys()];
			keys.forEach((key) => {
				const value = defaultToolTips.get(key);
				const overridden = overriddenTooltips?.get(key);
				const newValue = overridden ?? value;
				if (newValue) result.set(key, newValue);
			});
		}
		return result;
	}
	private toolTipsFrom(
		layout: Map<string, Layout> | undefined,
		contentKey: string
	): Map<string | number, string> | undefined {
		if (!layout) return undefined;
		return layout?.get(contentKey)?.toolTips;
	}
	display(contentKey: string): boolean {
		const overriddenDisplay = this.displayFrom(this.overriddenContent?.layout, contentKey);
		const defaultDisplay = this.displayFrom(this.content?.layout, contentKey);
		if (overriddenDisplay !== undefined) {
			return overriddenDisplay;
		} else if (defaultDisplay !== undefined) {
			return defaultDisplay;
		}
		//If no config hide the control
		return false;
	}
	private displayFrom(layout: Map<string, Layout> | undefined, contentKey: string): boolean | undefined {
		return layout?.get(contentKey)?.display;
	}
	setupControl(
		form: FormGroup,
		formControlName: string,
		value: any,
		validators?: ValidatorFn | ValidatorFn[]
	): FormControl {
		const formControl = new FormControl(value);
		if (form.get(formControlName) == null) form.addControl(formControlName, formControl);
		form.get(formControlName)?.patchValue(value, { emitEvent: false });
		//only apply validators if the control is configured to be visible
		if (this.display(formControlName) && validators) form.get(formControlName)?.setValidators(validators);
		return formControl;
	}
}
