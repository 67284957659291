import { Component, Input } from "@angular/core";
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from "@angular/forms";
import { GenericLookup } from "@limestone/ls-shared-modules";
import { BehaviorSubject, combineLatest, filter, map, take } from "rxjs";
import { DisplayConfig } from "src/app/Models";
import { TextStyle } from "src/app/Models/Enums";

@Component({
	selector: "ls-radio-buttons",
	templateUrl: "./radio-buttons.component.html",
	styleUrls: ["./radio-buttons.component.scss"],
	viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class RadioButtonsComponent {
	private _name = new BehaviorSubject<string | undefined>(undefined);
	private _lookups = new BehaviorSubject<GenericLookup<string | number>[] | undefined>(undefined);
	@Input() set lookups(lookups: GenericLookup<string | number>[] | undefined) {
		this._lookups?.next(lookups);
	}
	get lookups() {
		return this._lookups.value;
	}
	@Input() toolTips?: Map<string | number, string>;
	@Input()
	set controlName(name: string | undefined) {
		this._name.next(name);
	}
	get controlName() {
		return this._name.value;
	}
	@Input() value?: string | number;
	@Input() extraClasses?: string;
	radioButtonForm: FormGroup<any> = new FormGroup<any>({});
	constructor(public parentForm: FormGroupDirective) {
		combineLatest([this._lookups, this._name])
			.pipe(
				filter(([lu, n]) => !!lu && !!n),
				take(1),
				map(() => this.configureForm())
			)
			.subscribe();
	}

	configureForm() {
		if (!this.lookups || !this.controlName) console.warn("missing lookups or control name on radio-buttons");
		else {
			if (this.parentForm.form === null) {
				console.warn("Radio buttons should be in a form.");
				return;
			}
			this.radioButtonForm = this.parentForm.form;
			this.radioButtonForm.addControl(this.controlName, new FormControl(this.value));
		}
	}
	getTooltip(id: number | string | undefined) {
		if (!id) return undefined;
		const idAsString = id.toString();
		if (this.toolTips?.get) {
			const text = this.toolTips?.get(idAsString);
			if (text) {
				return new DisplayConfig(text, TextStyle.BODY_MEDIUM);
			}
		}
		return undefined;
	}
}
