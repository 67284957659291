import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { delay, filter, map, takeUntil } from "rxjs/operators";
import { CompanyProfileActions, CompanyProfileSelectors } from "../../../../AppStateManagement";
import { FormControl, FormGroup } from "@angular/forms";
import { IColumnHeader } from "../../../../Models/Interfaces/IColumnHeader";
import { AuthorizeRolesService } from "../../../../../services";
import { Subject, combineLatest, take } from "rxjs";
import { Actions, ofType } from "@ngrx/effects";
import { MdbModalService } from "mdb-angular-ui-kit/modal";
import { DeleteUserComponent, InviteUserComponent } from "../../Modals";
import { ModalActions } from "../../../../Core";
import { ModalResult, UserInvite } from "../../Modals/invite-user/invite-user.component";
import { CompanyProfile, CompanyUser } from "../../../../Models";
import { GridService } from "src/services/grid.service";
import {
	FilterAction,
	FilterJunction,
	GenericLookup,
	LsRole,
	PageRequest,
	ActiveFilter,
	FeatureFlagSelectors,
	FeatureFlags
} from "@limestone/ls-shared-modules";
import { IAlert } from "src/app/Models/Interfaces";

@Component({
	selector: "ls-users",
	templateUrl: "./users.component.html",
	styleUrls: ["./users.component.scss"]
})
export class UsersComponent implements OnInit, OnDestroy {
	public columns: IColumnHeader[] = CompanyUser.ColumnNames;
	public users?: CompanyUser[];
	public filteredUsers?: CompanyUser[];
	public changedUser?: CompanyUser;
	public form: FormGroup = new FormGroup({
		search: new FormControl(null)
	});
	public readonly SEARCH_CONTROL: string = "search";
	public readonly ROLES: GenericLookup<string>[] = [
		{ id: LsRole.EADMIN, name: "External Admin", isActive: true },
		{ id: LsRole.ECONTRIB, name: "External Contributor", isActive: true }
	];
	public CONTRIBUTOR_SUBTEXT = "Can apply for financing and update existing information.";
	public ADMIN_SUBTEXT = "Can invite and remove users, apply for financing & update information.";
	public options: any[] = [];
	public editIndex: number | null = null;
	public actionMessage?: IAlert;
	private componentTeardown$: Subject<any> = new Subject<any>();
	private cp?: CompanyProfile;
	private _delayTime = 5000;
	public isV1_0_3_FlagEnabled?: boolean;

	@HostListener("window:keyup.escape")
	onEscape() {
		this.handleEditIndex();
	}

	constructor(
		private store: Store,
		private cpSelectors: CompanyProfileSelectors,
		public authRolesService: AuthorizeRolesService,
		public actions$: Actions,
		public gridService: GridService<CompanyUser>,
		private dialog: MdbModalService,
		public featureFlagSelectors: FeatureFlagSelectors
	) {}

	public ngOnInit(): void {
		combineLatest(
			this.store.select(this.cpSelectors.selectCompanyProfile),
			this.store.select(this.featureFlagSelectors.selectFeatureFlags)
		)
			.pipe(
				takeUntil(this.componentTeardown$),
				map(([cp, ff]) => {
					this.cp = cp;
					this.users = this.cp?.users;
					this.filteredUsers = this.cp?.users;
					this.users?.forEach((u) => this.form.addControl(`${u.userId}`, new FormControl(u.userRole?.id)));
					this.isV1_0_3_FlagEnabled = ff!.find((f) => f.id === FeatureFlags.Onboarding_V1_0_3)!.enabled;
				})
			)
			.subscribe();

		this.form.valueChanges
			.pipe(
				takeUntil(this.componentTeardown$),
				map((formValue) => {
					Object.keys(formValue).forEach((key) => {
						const indx = this.users!.findIndex((u) => u.userId!.toString() === key);
						if (indx > -1) {
							if (this.form.get(key)?.dirty) {
								const role = this.ROLES.find((r) => r.id?.toString() === formValue[key]);
								this.users![indx].userRole = role;
								this.store.dispatch(CompanyProfileActions.updateCompanyProfileUser({ user: this.users![indx] }));
							}
						}
					});
				})
			)
			.subscribe();

		this.form
			.get(this.SEARCH_CONTROL)
			?.valueChanges.pipe(
				takeUntil(this.componentTeardown$),
				map((searchValue) => {
					this.searchUsers(searchValue);
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				ofType(CompanyProfileActions.setCompanyProfileUsers),
				takeUntil(this.componentTeardown$),
				filter((action) => !!action.users),
				map((action) => {
					this.filteredUsers = action.users;
				})
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileActions.companyProfileUserInviteSuccessful),
				map((action) => {
					this.editIndex = null;
					this.actionMessage = {
						type: "success",
						message: `Invitation sent to ${action.user.emailAddress}.`,
						icon: "check_circle"
					};
				}),
				delay(this._delayTime),
				map(() => this.removeMessage())
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileActions.companyProfileUserDeleteSuccessful),
				map((action) => {
					this.editIndex = null;
					this.actionMessage = {
						type: "info",
						message: `You've successfully removed ${action.user.firstName} (${action.user.emailAddress}).`,
						icon: "info"
					};
				}),
				delay(this._delayTime),
				map(() => this.removeMessage())
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileActions.companyProfileUserSaveSuccessful),
				map(() => {
					if (this.changedUser) {
						this.actionMessage = {
							type: "success",
							message: `You've successfully changed ${this.changedUser?.firstName}
								(${this.changedUser?.emailAddress}) to ${this.changedUser?.userRole?.name!.split(" ")[1]} role.`,
							icon: "check_circle"
						};
					}
					this.changedUser = undefined;
					this.editIndex = null;
				}),
				delay(this._delayTime),
				map(() => this.removeMessage())
			)
			.subscribe();

		this.actions$
			.pipe(
				takeUntil(this.componentTeardown$),
				ofType(CompanyProfileActions.companyProfileUserSaveUnsuccessful),
				map((action) => {
					const errorMap = action.error.errors;
					if (errorMap.has("Duplicate User")) {
						this.actionMessage = {
							type: "danger",
							message: errorMap.get("Duplicate User")![0],
							icon: "error"
						};
					} else {
						this.actionMessage = {
							type: "danger",
							message: errorMap.get("General")![0],
							icon: "error"
						};
					}
				}),
				delay(this._delayTime),
				map(() => this.removeMessage())
			)
			.subscribe();

		this.gridService.sortFilterChange$
			.pipe(
				takeUntil(this.componentTeardown$),
				map((pr: PageRequest) => {
					this.store.dispatch(
						CompanyProfileActions.getCompanyProfileUsers({
							companyId: this.cp!.companyId!,
							pr
						})
					);
				})
			)
			.subscribe();

		this.ROLES?.forEach((role) => {
			this.options.push({
				iconType: role.id === LsRole.EADMIN ? "verified_user" : "person",
				name: role.name?.split(" ")[1],
				subText: role.id === LsRole.EADMIN ? this.ADMIN_SUBTEXT : this.CONTRIBUTOR_SUBTEXT,
				roleId: role.id?.toString()
			});
		});
	}

	public ngOnDestroy() {
		this.componentTeardown$.next(null);
		this.componentTeardown$.complete();
	}

	public getRoleName(lsRole?: GenericLookup<string>): string {
		if (lsRole) {
			if (lsRole.name!.split(" ").length > 1) {
				return lsRole.name!.split(" ")[1];
			}
		}
		return "";
	}

	private removeMessage(isAction = true) {
		if (isAction) {
			this.actionMessage = undefined;
		}
	}

	private checkRoles(roles: LsRole[] | undefined): boolean {
		return this.authRolesService.checkRoles(roles!);
	}

	private isSelf(userId: number): boolean {
		const indx = this.users!.findIndex((u) => u.b2cId === this.authRolesService.getB2cId());
		if (indx > -1) {
			return userId === this.users![indx].userId;
		}
		return false;
	}

	public handleEditIndex() {
		if (this.editIndex !== null && this.editIndex >= 0) this.editIndex = null;
	}

	public canEdit(userId: number): boolean {
		return !this.isSelf(userId) && this.checkRoles([LsRole.EADMIN]);
	}

	public deleteUser(user: CompanyUser) {
		if (this.canEdit(user.userId!)) {
			this.dialog
				.open(DeleteUserComponent, {
					modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
					ignoreBackdropClick: true,
					data: {
						user
					}
				})
				.onClose.pipe(
					take(1),
					filter((action: ModalActions) => action === ModalActions.PRIMARY),
					map(() => {
						this.store.dispatch(CompanyProfileActions.deleteCompanyProfileUser({ user }));
					})
				)
				.subscribe();
		}
	}

	public inviteUser() {
		this.dialog
			.open(InviteUserComponent, {
				modalClass: "modal-dialog-centered modal-fullscreen-sm-down modal-lg",
				ignoreBackdropClick: true,
				data: {
					companyName: this.cp?.legalBusinessName,
					roles: this.ROLES
				}
			})
			.onClose.pipe(
				take(1),
				filter((result: ModalResult) => result.action === ModalActions.PRIMARY),
				map((result) => {
					this.inviteUserDispatch(result.user);
				})
			)
			.subscribe();
	}

	public canResend(lastInviteSent?: Date): boolean {
		if (!lastInviteSent || !this.isV1_0_3_FlagEnabled) {
			return true;
		}
		return Date.now() - new Date(lastInviteSent).getTime() > 86_400_000; // milliseconds in a day
	}

	public getTimeUntilResend(lastInviteSent?: Date): string {
		if (this.canResend(lastInviteSent)) {
			return "Send Invite";
		} else {
			const hoursLeft = Math.ceil((86_400_000 - (Date.now() - new Date(lastInviteSent!).getTime())) / 3_600_000);
			return `Invite may be resent in ${hoursLeft} hour${hoursLeft !== 1 ? "s" : ""}.`;
		}
	}

	public resendInvite(user: CompanyUser) {
		if (this.canResend(user.lastInviteSent)) {
			this.store.dispatch(CompanyProfileActions.resendInviteCompanyProfileUser({ user }));
		}
	}

	private inviteUserDispatch(user?: UserInvite) {
		const role = this.ROLES.find((r) => r.id === user?.role);
		const cu: CompanyUser = {
			companyId: this.cp?.companyId,
			firstName: user?.firstName,
			lastName: user?.lastName,
			emailAddress: user?.email,
			userRole: role
		};
		this.store.dispatch(CompanyProfileActions.inviteCompanyProfileUser({ user: cu }));
	}

	public searchUsers(searchValue: string) {
		// Split the search query into firstName and lastName if space is found in search value
		const [firstName = "", lastName = ""] = searchValue.trim().split(/\s+/);

		const filters = new Map<string, ActiveFilter[]>();

		if (firstName && lastName) {
			filters.set("firstName", [
				{
					filterAction: FilterAction.CONTAINS,
					filterValue: firstName
				}
			]);
			filters.set("lastName", [
				{
					filterAction: FilterAction.CONTAINS,
					filterValue: lastName
				}
			]);
		} else if (searchValue) {
			// Create a commonFilterProperties object to store the shared filter properties.
			const commonFilterProperties = {
				filterAction: FilterAction.CONTAINS,
				filterValue: searchValue,
				filterJunction: FilterJunction.OR
			};

			["firstName", "lastName", "emailAddress"].forEach((field) => filters.set(field, [commonFilterProperties]));
		}

		const pr = new PageRequest(1, 100, undefined, filters);
		this.store.dispatch(
			CompanyProfileActions.getCompanyProfileUsers({
				companyId: this.cp!.companyId!,
				pr
			})
		);
	}

	public userRoleChanged(userRole: string, userId: number): void {
		const userControl = this.form.controls[userId.toString()];

		if (userControl.value !== userRole) {
			this.setChangedUser(userRole, userId);
			userControl.markAsDirty();
			userControl.setValue(userRole);
		} else {
			this.editIndex = null;
		}
	}

	public openUserRoleDropdown($event: MouseEvent, userId: number, index: number): void {
		if (!this.canEdit(userId)) {
			this.editIndex = null;
			return;
		}
		this.editIndex = index;
	}

	public dropdownHidden(index: number): void {
		if (this.editIndex === index) {
			this.editIndex = null;
		}
	}

	public setChangedUser(userRole: string, userId: number): void {
		const role = this.ROLES.find((role) => role.id!.toString() === userRole);
		this.changedUser = this.users!.find((user) => user.userId === userId);
		this.changedUser!.userRole = role;
	}
}
